
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
export default defineComponent({
  name: "sales-offer-contract-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  methods: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const SalesOfferContract = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const selectedOffers = ref<Array<any>>([]);
    const customer_data = ref<Array<any>>([]);
    const account_executive_data = ref<Array<any>>([]);
    const customer_branch_data = ref<Array<any>>([]);
    const pendingOffersList = ref([]);
    const se_id = ref('')
    const enquiry_customer_type = ref(1);

    const formData = ref({
      customer_select: "",
      customer_branch_select: "",
      buyer_select: "",
      buyer_branch_select:"",
      maintain_by:"",
    });

    const rules = ref({
      customer_select: [
        {
          required: true,
          message: "Customer Company is required",
          trigger: "change",
        },
      ],
      customer_branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      buyer_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      buyer_branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      maintain_by: [
        {
          required: true,
          message: "Maintain by is required",
          trigger: "change",
        },
      ],
    });

    const resetForm = () => {
      formData.value = {
        customer_select: "",
        customer_branch_select: "",
        buyer_select: "",
        buyer_branch_select:"",
        maintain_by:"",
      }
    }


    async function getCustomerCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    async function getCustomerBranchList(customerType = 'consignee') {
      let values = {
        company_id: customerType === 'consignee' ? formData.value["customer_select"] :
                    formData.value['buyer_select'],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAccountExecutiveData() {
      await store
        .dispatch(ActionsFi.CUST_GET_ACCOUNT_EXECUTIVE_LIST_DATA)
        .then(({ data }) => {
          account_executive_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getPendingSalesOffers = async () => {
      let params = { se_detail_id: props.data?.se_detail_id };
      await store
        .dispatch(ActionsFi.CUST_GET_SALES_OFFER_BY_COMPANY_LIST, params)
        .then(({ data }) => {
          if (data?.length) {
            se_id.value = data[0].se_id;
            
            let dataWithextrafileds = data?.map((item, index) => {
              return Object.assign({ order_qty: "", isCheck: false }, item);
            });
            pendingOffersList.value = dataWithextrafileds;
            getSalesEnquiryDetails();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getSalesEnquiryDetails() {
      let params = {se_id: se_id.value}
      await store
        .dispatch(ActionsFi.CUST_GET_OFFER_DETAILS_BY_INQUIRY_ID, params)
        .then(({ data }) => {
          let salesEnquiryData = data;
          enquiry_customer_type.value = salesEnquiryData?.customer_type;
          console.log('cust Type ', enquiry_customer_type.value)
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val) => {
      selectedOffers.value = val;
      console.log('seOffers', selectedOffers.value)
    };


    onMounted(async () => {
      SalesOfferContract.value?.addEventListener(
        "show.bs.modal",
        async function (event) {
          await getPendingSalesOffers();
          await getAccountExecutiveData();
        }
      );
    });

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };


    const setCompanyData = async (data) => {
      loading.value = true;
      let user = JSON.parse(JwtService.getToken());

      let seOfferIds = selectedOffers.value.map((item) => {
        return item.offer_id;
      });

      if (seOfferIds.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select at leat one offer.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return false;
      }

      const requestData = {
        user_id: user.user_id,
        se_detail_id: props.data?.se_detail_id,
        customer_company_id: enquiry_customer_type.value == 0 ? data.buyer_select : 0,
        customer_company_branch_id: enquiry_customer_type.value == 0 ? data.buyer_branch_select : 0,
        consignee_company_id: data.customer_select,
        consignee_company_branch_id: data.customer_branch_select,
        se_offer_ids: seOfferIds

      };
      console.log('requestBody', requestData)

      await store
        .dispatch(ActionsFi.CUST_CREATE_SALES_CONTRACT, requestData)
        .then(({ data, is_error, status, message }) => {
          if (data && status !== 500) {
            setTimeout(() => {
              loading.value = false;
              hideModal(SalesOfferContract.value);
              Swal.fire({
                html: ""+

                      data?.sc_no+

                      "<br>Sales Offer created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);
              });
            }, 2000);
          } else if (data == "" && is_error == false && status == 500) {
            loading.value = false;
            Swal.fire({
              text: message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              // hideModal(SalesOfferContract.value);
              // router.go(0);
            });
          } else {
            loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          loading.value = false;
          console.log(response);
        });
    };

    return {
      submit,
      loading,
      formData,
      formRef,
      rules,
      getCustomerCompanyList,
      getCustomerBranchList,
      account_executive_data,
      customer_branch_data,
      customer_data,
      SalesOfferContract,
      enquiry_customer_type,
      handleSelectionChange,
      resetForm,
      pendingOffersList,
    };
  },
});
